import { colorModeInit } from '@/utils/colormode';
import Head from 'next/head';
import { PropsWithChildren, useEffect } from 'react';

type SeoProps = {
  seo_html_title: string;
  seo_meta_description: string;
  seo_og_title: string;
  seo_og_description: string;
  seo_og_url: string;
  seo_og_image: string;
  seo_og_type: string;
  seo_twitter_title: string;
  seo_twitter_description: string;
  seo_twitter_url: string;
  seo_twitter_image: string;
  seo_meta_robots: string;
  canonical_link: string;
};

const defaultSeoProps: Partial<SeoProps> = {};

export type Props = {
  title: string;
  seo: Partial<SeoProps>;
};

function BasePage(props: PropsWithChildren<Props>) {
  const { children, seo = defaultSeoProps, title } = props;

  useEffect(() => {
    colorModeInit();
  }, []);

  const {
    seo_html_title: seoHtmlTitle,
    seo_meta_description: seoMetaDescription,
    seo_og_title: seoOgTitle,
    seo_og_description: seoOgDescription,
    seo_og_url: seoOgUrl,
    seo_og_image: seoOgImage,
    seo_og_type: seoOgType,
    seo_twitter_title: seoTwitterTitle,
    seo_twitter_description: seoTwitterDescription,
    seo_twitter_url: seoTwitterUrl,
    seo_twitter_image: seoTwitterImage,
    seo_meta_robots: seoMetaRobots,
    canonical_link: canonicalLink,
  } = seo;

  const defaultDescription =
    'Die Werkbank ist eine Digitalagentur aus Bochum. In unserem ContentHub sammeln wir unsere Werkzeuge und Ideen.';
  const defaultUrl = 'https://labs.werkbank.de';
  const defaultTitle = 'Der Werkbank ContentHub';

  return (
    <>
      <Head>
        <title>{seoHtmlTitle ?? title}</title>
        <link rel='icon' href='/favicon.ico' />
        <meta name='description' content={seoMetaDescription || defaultDescription} />
        <meta property='og:title' content={seoOgTitle || defaultTitle} />
        <meta property='og:description' content={seoOgDescription || defaultDescription} />
        <meta property='og:url' content={seoOgUrl || defaultUrl} />
        <meta property='og:image' content={seoOgImage} />
        <meta property='og:type' content={seoOgType || 'article'} />

        <meta property='twitter:title' content={seoTwitterTitle || defaultTitle} />

        <meta
          property='twitter:description'
          content={seoTwitterDescription || defaultDescription}
        />
        <meta property='twitter:url' content={seoTwitterUrl || defaultUrl} />
        <meta property='twitter:image' content={seoTwitterImage} />
        <meta name='robots' content={seoMetaRobots} />
        {canonicalLink ? <link rel='canonical' href={canonicalLink} /> : null}
      </Head>
      <div className='base-page'>{children}</div>
    </>
  );
}

export default BasePage;
